import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

//Import Icons

// import images

export default class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1"
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  componentDidMount() {
    document.body.classList = "";
    // document.querySelector(".shoppingbtn").classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn").classList.add("btn-primary");
        document.querySelector(".shoppingbtn").classList.remove("btn-light");
        document.querySelector(".settingbtn").classList.add("btn-soft-primary");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="bg-half-100 bg-light d-table w-100">
          <Container>
            {/* <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0"> How it works </h4>
                </div>
              </Col>
            </Row> */}
            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item"><Link to="/">Zenstrin</Link></li>{" "}
                  <li className="breadcrumb-item"><Link to="/ai-search">AI Search</Link></li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">How it works</li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <Nav tabs className="nav-pills nav-justified flex-column flex-sm-row rounded">
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === "1" })}
                      onClick={() => { this.toggleTab("1"); }}
                    >
                      <div className="text-center py-2">
                        <h6 className="mb-0">For Renters/Buyers</h6>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === "2" })}
                      onClick={() => { this.toggleTab("2"); }}
                    >
                      <div className="text-center py-2">
                        <h6 className="mb-0">For Agents</h6>
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="timeline-page pt-2 position-relative">
                      <div className="timeline-item mt-4">
                        <Row>
                          <Col lg={6} md={6} sm={6}>
                            {/* <div className="duration date-label-left border rounded p-2 px-4 position-relative shadow">
                              2002
                            </div> */}
                          </Col>
                          <Col lg={6} md={6} sm={6}>
                            <Card className="event event-description-right rounded shadow border-0 overflow-hidden float-start">
                              {/* <img src={onboarding} className="img-fluid" alt="" /> */}
                              <CardBody>
                                <h5 className="mb-0 text-capitalize">Gathering Information</h5>
                                <p className="mt-3 mb-0 text-muted">
                                  Message our AI and let it know what type of property you are looking for.
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </div>

                      <div className="timeline-item mt-4">
                        <Row>
                          <Col lg={6} md={6} sm={6} className="order-sm-1 order-2">
                            <Card className="event event-description-left rounded shadow border-0 overflow-hidden float-start">
                              {/* <img src={rentalApplications} className="img-fluid" alt="" /> */}
                              <CardBody>
                                <h5 className="mb-0 text-capitalize">
                                  AI Search
                                </h5>
                                <p className="mt-3 mb-0 text-muted">
                                  We will search for agents with properties that match your criteria and send them your information.
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={6} md={6} sm={6} className="order-sm-2 order-1">
                            {/* <div className="duration duration-right rounded border p-2 px-4 position-relative shadow text-start">
                              2006
                            </div> */}
                          </Col>
                        </Row>
                      </div>

                      <div className="timeline-item mt-4">
                        <Row>
                          <Col lg={6} md={6} sm={6}>
                            {/* <div className="duration date-label-left border rounded p-2 px-4 position-relative shadow">
                              2012
                            </div> */}
                          </Col>
                          <Col lg={6} md={6} sm={6}>
                            <Card className="event event-description-right rounded shadow border-0 overflow-hidden float-start">
                              {/* <img src={todo} className="img-fluid" alt="" /> */}
                              <CardBody>
                                <h5 className="mb-0 text-capitalize">Agent Contacts you</h5>
                                <p className="mt-3 mb-0 text-muted">
                                  If the agent is interested and thinks you are a good fit, they will contact you.
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </div>

                      <div className="timeline-item mt-4">
                        <Row>
                          <Col lg={6} md={6} sm={6} className="order-sm-1 order-2">
                            <Card className="event event-description-left rounded shadow border-0 overflow-hidden float-start">
                              {/* <img src={tasks} className="img-fluid" alt="" /> */}
                              <CardBody>
                                <h5 className="mb-0 text-capitalize">Move in</h5>
                                <p className="mt-3 mb-0 text-muted">
                                  Once you have found the perfect property, you can move in.
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={6} md={6} sm={6} className="order-sm-2 order-1">
                            {/* <div className="duration duration-right rounded border p-2 px-4 position-relative shadow text-start">
                              2013
                            </div> */}
                          </Col>
                        </Row>
                      </div>

                    </div>
                  </TabPane>

                  <TabPane tabId="2">
                    <div className="timeline-page pt-2 position-relative">
                      <div className="timeline-item mt-4">
                        <Row>
                          <Col lg={6} md={6} sm={6}>
                            {/* <div className="duration date-label-left border rounded p-2 px-4 position-relative shadow">
                              2002
                            </div> */}
                          </Col>
                          <Col lg={6} md={6} sm={6}>
                            <Card className="event event-description-right rounded shadow border-0 overflow-hidden float-start">
                              {/* <img src={onboarding} className="img-fluid" alt="" /> */}
                              <CardBody>
                                <h5 className="mb-0 text-capitalize">Gathering Information</h5>
                                <p className="mt-3 mb-0 text-muted">
                                  Tell us what type of properties you offer and what areas you cover.
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </div>

                      <div className="timeline-item mt-4">
                        <Row>
                          <Col lg={6} md={6} sm={6} className="order-sm-1 order-2">
                            <Card className="event event-description-left rounded shadow border-0 overflow-hidden float-start">
                              {/* <img src={rentalApplications} className="img-fluid" alt="" /> */}
                              <CardBody>
                                <h5 className="mb-0 text-capitalize">
                                  Matching with clients
                                </h5>
                                <p className="mt-3 mb-0 text-muted">
                                  We will match you with clients that are looking for properties that you offer.
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={6} md={6} sm={6} className="order-sm-2 order-1">
                            {/* <div className="duration duration-right rounded border p-2 px-4 position-relative shadow text-start">
                              2006
                            </div> */}
                          </Col>
                        </Row>
                      </div>

                      <div className="timeline-item mt-4">
                        <Row>
                          <Col lg={6} md={6} sm={6}>
                            {/* <div className="duration date-label-left border rounded p-2 px-4 position-relative shadow">
                              2012
                            </div> */}
                          </Col>
                          <Col lg={6} md={6} sm={6}>
                            <Card className="event event-description-right rounded shadow border-0 overflow-hidden float-start">
                              {/* <img src={todo} className="img-fluid" alt="" /> */}
                              <CardBody>
                                <h5 className="mb-0 text-capitalize">Contact the client</h5>
                                <p className="mt-3 mb-0 text-muted">
                                  If you are interested in a client, you can contact them.
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
