import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import ReactDrawer from 'react-drawer';

//Import Components
import RightSidebar from "../../components/Layout/RightSidebar";
// import Section from "./section";
import TopSection from "./TopSection";
// import Partners from "./Partners";
import HowItWorks from "./HowItWorks";
// import Feature from "../../components/Shared/Feature";
import Features from "./Features";
// import FeatureDescription from "./FeatureDescription";
// import CTA from "./cta";
import Trial from "./Trial";
// import Pricings from "./pricings";
// import { featureArrayPropertyManagers, facilities, testimonials } from "../../common/data";
// import classic02 from "../../assets/images/saas/classic02.png";
// import myclassic1 from "../../assets/images/property-management/myclassic1.png";
// import myclassic2 from "../../assets/images/property-management/myclassic2.png";
// import myclassic3 from "../../assets/images/property-management/myclassic3.png";
// import myclassic4 from "../../assets/images/property-management/myclassic4.png";
// import myclassic5 from "../../assets/images/property-management/myclassic5.png";
// import myclassic6 from "../../assets/images/property-management/myclassic6.png";
// import Popup from "../../components/Layout/popup";

//Import Images

const AISearch = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    // Add WhatsApp redirect check
    const params = new URLSearchParams(window.location.search);
    if (params.get('whatsapp') === 'yes') {
      window.location.href = "https://wa.me/+2348070956910?text=Hello";
      return;
    }

    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light")
      navLink.classList.remove("btn-soft-primary");
    });
    window.addEventListener("scroll", scrollNavigation, true);
  })

  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
      document.querySelector(".settingbtn")?.classList.remove("btn-light");
      document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
      document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
      document.getElementById("topnav")?.classList.add("nav-sticky");
    } else {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-primary");
      document.querySelector(".settingbtn")?.classList.remove("btn-soft-primary");
      document.querySelector(".shoppingbtn")?.classList.add("btn-light");
      document.querySelector(".settingbtn")?.classList.add("btn-light");
      document.getElementById("topnav")?.classList.remove("nav-sticky");
    }
  };

  const onDrawerClose = () => {
    setDrawerOpen(false);
  };

  const toggleDrawer = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const openWhatsApp = () => {
    window.open("https://wa.me/+2348070956910?text=Hello", "_blank", "noopener,noreferrer");
  };

  return (
    <React.Fragment>
      {/* render section */}
      <TopSection openWhatsApp={openWhatsApp} />

      <section className="overflow-hidden mb-5">
        <HowItWorks />
        <Trial openWhatsApp={openWhatsApp} />
      </section>

      <ReactDrawer
        open={isDrawerOpen}
        position={"right"}
        onClose={onDrawerClose}
      >
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  );
}

export default AISearch;
