import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "reactstrap";
import { Link } from "react-router-dom";

//Import Image
import img from "../../assets/images/onepage/bg.png";
import classic from "../../assets/images/property-management/classic.png";

const TopSection = ({ openWhatsApp }) => {
  // Add state for phone number
  const [phoneNumber, setPhoneNumber] = React.useState('');

  // Handle phone number changes
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    
    // Ensure it always starts with + and only contains numbers
    if (value.startsWith('+')) {
      // Remove non-digit characters except the leading +
      const cleaned = '+' + value.slice(1).replace(/[^\d]/g, '');
      console.log(phoneNumber);
      setPhoneNumber(cleaned);
    }
  };

  return (
    <React.Fragment>
      <section
        className="bg-home bg-light d-flex align-items-center"
        style={{ background: `url(${img}) top left no-repeat`, height: "auto" }}
        id="home"
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="9" className="text-center mt-0 mt-md-5 pt-0 pt-md-5">
              <div className="title-heading margin-top-100">
                <h1
                  className="heading mb-3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Unlocking Effortless Property<br />
                  <span style={{ color: "#f7961c" }}>Connections with AI</span>
                </h1>
                <p
                  className="para-desc mx-auto text-muted"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  Zenstrin AI Search simplifies property matchmaking—helping you find the right property, clients, or agents with ease.
                </p>
                <div
                  className="text-center subcribe-form mt-4 pt-2"
                  data-aos="fade-up"
                  data-aos-duration="1800"
                >
                  <Form>
                    <div className="mb-0">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="shadow bg-white rounded-pill"
                        required
                        placeholder="WhatsApp Number +XXXXXXXXXX"
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        minLength="8"
                        maxLength="15"
                      />
                      <Button
                        type="submit"
                        color="primary"
                        className="btn-pills"
                        onClick={openWhatsApp}
                      >
                        Try AI Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>

              {/* <div
                className="home-dashboard onepage-hero"
                data-aos="fade-up"
                data-aos-duration="2200"
              >
                <img src={classic} alt="" className="img-fluid rounded" />
              </div> */}
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default TopSection;
